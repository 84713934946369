// スクロールを無効にする
/* document.addEventListener('touchmove', function(e) {e.preventDefault();}, {passive: false});


$(document).ready(function(){ */

const switchBoundary = (initObj) => {

/*
body要素のdata属性からウィンドウサイズを取得
*/
    const innerW = $('body').data('innerW');
    const selectedCreator = innerW - 89;

    const musicDetailHeight = $('.music-detail').css('bottom');

    
    const openMusicBoundary = () => {
         // boundaryエリアの表示・領域サイズの変更
         $('.video-boundary-info').animate({
            width: 0
        },200)
        $('.video-boundary-icon').animate({
            width: 39,
            height: 39,
            marginTop: 20,            
        },200)

        $('.music-boundary-info').animate({
            width: initObj.infoW
        },200)
        $('.music-boundary-icon').animate({
            width: 59,
            height: 59,
            marginTop: 0,            
        },200)

        $('.music-boundary').css({
            width: selectedCreator,
        },)

        // content-style部分の表示切り替え
        $('.content-style-video-text').animate({
            opacity:0
        },200)

        $('.content-style-video').animate({
            opacity:.33
        },200)

        $('.content-style-music-text, .content-style-music').animate({
            opacity:1
        },200)

        // 状態をそれぞれのboundaryのdata属性に保存
        $('.music-boundary').data("isopen", true)
        $('.video-boundary').data("isopen", false)
        $('.content-style-video').data("isopen", false)
        $('.content-style-music').data("isopen", true)

        // 0.2秒遅れでスワイプ・タップエリアを確保（一瞬崩れて見えるのを防ぐため）
        setTimeout(() => {
            $('.video-boundary').css({
                width: 58,
            },)              
        }, 200);
    }

    const openVideoBoundary = () => {
        // boundaryエリアの表示・領域サイズの変更
        $('.music-boundary-info').animate({
            width:0
        },200)
        $('.music-boundary-icon').animate({
            width: 39,
            height: 39,
            marginTop: 20,            
        },200)    

        $('.video-boundary-info').animate({
            width: initObj.infoW
        },200)
        $('.video-boundary-icon').animate({
            width: 59,
            height: 59,
            marginTop: 0,            
        },200)               

        $(' .video-boundary').css({
            width: selectedCreator,
        })

        // content-style部分の表示切り替え
        $('.content-style-music-text').animate({
            opacity:0
        },200)

        $('.content-style-music').animate({
            opacity:.33
        },200)

        $('.content-style-video-text, .content-style-video').animate({
            opacity:1
        },200)

        // 状態をそれぞれのboundaryのdata属性に保存
        $('.video-boundary').data("isopen", true)
        $('.music-boundary').data("isopen", false)
        $('.content-style-music').data("isopen", false)
        $('.content-style-video').data("isopen", true)

        // 0.2秒遅れでスワイプ・タップエリアを確保（一瞬崩れて見えるのを防ぐため）
        setTimeout(() => {
            $(' .music-boundary').css({
                width: 58,
            })               
        }, 200);
    }

    const closeMusicDetail = () => {
        $('.wall').css(
            {'display': 'none'}
        );

        $('.music-detail')
        .animate(
            {'bottom': musicDetailHeight}, 
            300,
        );

        setTimeout(() => {
            $('.music-detail').css(
                {'display': 'none'}
            );
        }, 300);
    }

    const openMusicDetail = () => {

        $('.music-detail').css(
            {'display': 'block'}
        )
        .animate(
            {'bottom': 0}, 
            300,
        );

        setTimeout(() => {
            $('.wall').css(
                {'display': 'block'}
            );
        }, 300);
    }

    const openVideoDetail = () => {
        console.log("openVideoDetail")
    }

    const openMusicStyles = () => {
        $('.music-style-detail').css(
            {'display': 'block'}
        )
        .animate(
            {'bottom': 0}, 
            300,
        );

        setTimeout(() => {
            $('.wall').css(
                {'display': 'block'}
            );
        }, 300);
    }

    const closeMusicStyles = () => {
        $('.wall').css(
            {'display': 'none'}
        );

        $('.music-style-detail')
        .animate(
            {'bottom': musicDetailHeight}, 
            300,
        );

        setTimeout(() => {
            $('.music-style-detail').css(
                {'display': 'none'}
            );
        }, 300);
    }

    const openVideoStyles = () => {
        console.log("openVideoStyles")
    }

    const controlMusicBoundary = () => {

            let _this = $('.music-boundary');

            let musicIsOpen = _this.data("isopen");
    
            if(musicIsOpen){
                // 音楽の詳細を開く
                openMusicDetail();
            } else {
                openMusicBoundary();
            }
    }


    const controlVideoBoundary = () => {

        let _this = $('.video-boundary');

        let videoIsOpen = _this.data("isopen");

        if(videoIsOpen){
            
            openVideoDetail();

        } else {

            openVideoBoundary();

        }

    }

    const controlMusicStyle = () => {

        let _this = $('.content-style-music');
        let musicStyleIsOpen = _this.data("isopen");

        if(musicStyleIsOpen){
            openMusicStyles();
        } else {
            openMusicBoundary();
        }

    }

    const controlVideoStyle = () => {

        let _this = $('.content-style-video');
        let videoStyleIsOpen = _this.data("isopen");

        if(videoStyleIsOpen){
            openVideoStyles();
        } else {
            openVideoBoundary();
        }

    }


/*
イベントトリガー
*/
    $('.music-boundary').bind(
        'click', 
        controlMusicBoundary
    );

    $('.content-style-music').bind(
        'click', 
        controlMusicStyle
    );

    $('.video-boundary').bind(
        'click',
        controlVideoBoundary
    );

    $('.content-style-video').bind(
        'click', 
        controlVideoStyle
    );

    $('.wall, .music-detail-close').bind(
        'click',
        closeMusicDetail
    );

    $('.wall, .music-style-detail-close').bind(
        'click',
        closeMusicStyles
    );

};

/* }); */

export {switchBoundary};