import $ from 'jquery'
import '../css/style.scss'

import {envFile} from './envFile.js';
import {displayAdj} from './displayAdj.js';
import {switchBoundary} from './switchBoundary.js';
import {touchVideo} from './touchVideo.js';
import {touchMusic} from './touchMusic.js';
import {header} from './header.js';
import {intaractions} from './intaractions.js';

document.addEventListener('touchmove', function(e) {e.preventDefault();}, {passive: false});

$(document).ready(function(){

  const initObj = displayAdj();

  let env = {}
  env = envFile();

  console.log(env);

  switchBoundary(initObj);
  touchVideo(env);
  touchMusic(env);
  header();
  intaractions();

});