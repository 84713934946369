const envFile = () =>{
  let env = {}
  env.host = location.hostname
  env.storage = 'https://vmedia.droptune.net/'
  //env.storage = 'https://droptune-test01.s3-ap-northeast-1.amazonaws.com/'
  env.apiHost = ''

  let envHost = location.hostname

  if(envHost == 'localhost'){

    env.apiHost = 'http://localhost/api/'

  } else {

    env.apiHost = 'https://dockertest01.herokuapp.com/api/'

  }

  return env;
}
  
export {envFile};