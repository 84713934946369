const displayAdj = () => {
/*
アドレスバーなどを除いた表示領域のサイズを取得
*/
  const innerH = window.innerHeight;
  let lowAreaH = innerH - 118;

  const innerW = window.innerWidth;
  let selectedCreator = innerW - 79;
  const infoW = innerW - 152;

/*
body要素にdata属性で保存。使い回せるように
*/
  $('body').data('innerH', innerH).data('innerW', innerW);

/*
要素のwidthやheightのサイズを指定
*/
  $('.wrapper').css('height', innerH);
  $('.content-touch').css('height' , lowAreaH);
  $('.music-boundary').css('width', selectedCreator);

  $('.music-boundary-info').css('width', infoW);

  $('.menu-wrapper').css('height', innerH);
  $('.menu-wrapper').css('width', innerW);
  $('.menu-wrapper').css('right', innerW);

  $('.music-detail, .music-style-detail').css('height', innerH - 50);
  $('.music-detail, .music-style-detail').css('width', innerW);
  $('.music-detail, .music-style-detail').css('bottom', - (innerH - 50));

  $('.wall').css('height', innerH);
  $('.wall').css('width', innerW);

  const initObj = {
    infoW: infoW,
    innerH: innerH, 
  }


  return initObj;

};

export {displayAdj};