const intaractions = () => {

  let target = $('.intaraction01')
  let defaultOpacity = '';

  target.bind('touchstart', function(){

    defaultOpacity = $(this).css('opacity');

    $(this).animate({
      'opacity': .66
    },80);
  
  });

  target.bind('touchend', function(){

    $(this).animate({
      'opacity': defaultOpacity
    },80);
  
  });
  

};

export {intaractions};