// スクロールを無効にする
document.addEventListener('touchmove', function(e) {e.preventDefault();}, {passive: false});


/* $(document).ready(function(){ */

const touchVideo = (env) => {
/*
共通変数
*/
    let videoListObj = {}; //表示するビデオのリスト（後でajaxで取得）
    let windowHeight = $('body').data('innerH'); //デバイスのウインドウの高さ（アドレスバーなどを考慮してinnerHeightを取得 = bodyのdata属性に格納済み）
    let videoSwipeCount = 0; //スワイプの回数。表示するvideoに影響
    let videoSwipeLength = 0; //読み込んだオブジェクトの数。表示するvideoに影響

        //videoのアスペクト比にもとづくリピート表示 / フルスクリーン表示
    let displayRepeat = {
            "background-size" : "100%",
            "background-repeat" : "repeat",
        }              
    let displayFullscreen = {
            "background-size" : "cover",
            "background-repeat" : "no-repeat",
        } 

/*
    次のビデオ・前のビデオのセット
*/
    let prevDefaultPos = {
        "height" : windowHeight,
        "bottom" : windowHeight,
    }

    let nextDefaultPos = {
        "height" : windowHeight,
        "top" : windowHeight,
    }

    $(".content-prev").css(prevDefaultPos);
    $(".content-next").css(nextDefaultPos);


/*
    スワイプによるイベント発火
*/
    $(".content-video, .video-boundary").bind("touchstart", touchStart);
    $(".content-video, .video-boundary").bind("touchmove", touchMove);
    $(".content-video, .video-boundary").bind("touchend", touchEnd);


/*
    ロード時におすすめのvideo情報を取得するAjax
*/ 
    $.ajax({
        url: env.apiHost + "video/foryou",
        type: "GET",
        //data: data,
        timeout: 5000

        }).done(function(data){

            let recommendData = $.parseJSON(data);
                videoListObj = recommendData;
            let recommendVideos = recommendData;
                videoSwipeLength = videoListObj.length - 1;

            let firstVideo = recommendVideos[0];
            let secondVideo = recommendVideos[1];

            setVideo(firstVideo, 1, true);
            setVideo(secondVideo, 2);

        }).fail(function(){

        });

/*
    ビデオをセットする
*/
    function setVideo(data, status, isFirst){

        /* 
            引数statusでセット場所を分岐。
            0はprev, 1はcurrent, 2はnextにセット 
        */
        let viewStatus = "";

        switch (status){
            case 0:
                viewStatus = "prev";
                break;
            case 1:
                viewStatus = "current";
                break;
            case 2:
                viewStatus = "next";
                break;
        }

        let targetClass = ".content-" + viewStatus;

         /* 
            縦横比を取得し、縦長じゃないvideoはrepeat表示にする
        */
        let toLoadImg = new Image();
            toLoadImg.src = env.storage + 'video/' + data.id + '.gif';

        $(targetClass).find(".video-area").css("background-image", "url("+ toLoadImg.src +")");

        toLoadImg.onload = function(){
            let toLoadImg_W = toLoadImg.width;
            let toLoadImg_H = toLoadImg.height;
            let toLoadImg_AR = toLoadImg_W / toLoadImg_H;

            let isFull = true;

            /* 横長のときはフルスクリーンなしでrepeatあり */
            let contentDisplayStyle = {};
            if (toLoadImg_AR > 0.8){
                console.log(targetClass)
                contentDisplayStyle = displayRepeat;
                isFull = false;                 
            } else {
                contentDisplayStyle = displayFullscreen; 
            }

            $(targetClass).find(".video-area").css(contentDisplayStyle);
            $(targetClass).data("displayFull", isFull);
        };

        /* 最初の読み込み時は該当のboundaryクラスを指定 */
        let targetBoundaryClass = "";
        if(isFirst){
            targetBoundaryClass = ".content-creator";
        }
        $(targetBoundaryClass).find(".video-boundary-name").text(data.name);
        $(targetBoundaryClass).find(".video-boundary-title").text(data.title);
        $(targetBoundaryClass).find(".video-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + data.user_id + ".jpg)");

    }

/*
    video個別の情報を取得するajax
*/ 
    function getVideo(videoId){
        return $.ajax({
            url: "/api/get_video/" + videoId,
            type: "GET",
            //data: data,
            timeout: 5000
        })      
    }  


/*
    videoを要素内で大差中央にする関数
*/
    function adjustXPos(videoHeight, status){

        /* 
            引数statusでセット場所を分岐。
            0はprev, 1はcurrent, 2はnextにセット 
        */
       let viewStatus = "";

       switch (status){
        case 0:
            viewStatus = "prev";
            break;
        case 1:
            viewStatus = "display";
            break;
        case 2:
            viewStatus = "next";
            break;
        default:
            viewStatus = "display";
            break;
        }        

        let windowHeightHalf = windowHeight / 2;
        let videoHeightHalf = videoHeight / 2;
        let videoTop = windowHeightHalf - videoHeightHalf;
            videoTop = Math.floor(videoTop);
            videoTop = videoTop - 25;
        let firstVideoTop = parseInt(videoTop, 10);

        $(".content-"+ viewStatus).css("top", videoTop);
        $(".content-"+ viewStatus).data("startTop", videoTop);
        $(".content-creator .video-boundary").css("bottom", 60);

    }


/*
    タップした時
*/
    function touchStart( event ) {

        let startY = Position(event);

        //タップ位置をdataに格納
        $(".content-display").data("start-y",startY);
        $(".content-display").data("current-y",startY);

        //タップ時の時間
        let startTime = performance.now();
        $(".content-display").data("start-time",startTime);

    }


/*
    指を離した時
*/
    function touchEnd( event ) {
        //離したときの時間
        let endTime = performance.now();
        let startTime = $(".content-display").data("start-time");
        let swipeDur = endTime - startTime;

        // デフォルトのTOP位置を変数に入れる
        let startTop = $(".content-display").data("startTop");

        let startTopPx = startTop + "px";

            // 指を離した位置
        let touchEndY = $(".content-display").data("current-y");

            // タップを開始した位置
        let touchStartY = $(".content-display").data("start-y");

            // スワイプ距離を求める
        let swipeDist = touchStartY - touchEndY
        let swipeDistAbs = Math.abs(swipeDist);

        //スワイプ時間が100ms以下、スワイプ距離が60px以上なら切り替え
        if (swipeDur < 100 && swipeDist > 60) {

            nextVideo();

            } else if( swipeDur < 100 && swipeDist < (-60) ){

                prevVideo();

            //スワイプ距離が200px以上なら切り替え。199px以下ならもとに戻る
            } else if (swipeDistAbs < 200) {

                returnCenter();

            //上の境界までスワイプして消える
            } else if (swipeDist > 0) {

                nextVideo();

            //下の境界までスワイプして消える
            } else if (swipeDist < 0) {

                prevVideo();

            }


        function nextVideo(){

            if (videoSwipeCount == videoSwipeLength){
                returnCenter();
                return;
            }

            let scrollDist = 0 - (windowHeight);
            let boudaryScrollDist =  windowHeight + 60;
                //nextVideoHeight = $(".next-tn img").height()
            let nextVideoHeight = windowHeight;

            /*
            nextがcurrent位置へ
            currentがprev位置へ
            移動しきったら
            */
            $.when(
                //adjustXPos(nextVideoHeight),
                $(".content-display").animate({top : scrollDist}, 200),
                $(".video-boundary").animate({bottom : boudaryScrollDist}, 200),
                $(".content-next").animate({top : 0}, 200),
            /*
            nextの情報をcurrentに再現(API使う)
            currentの情報をprevに再現
            nextとcurrentの位置を戻し
            nextに新しい動画を読み込む
            */
            ).done(function(){
                
                let currentObj = videoListObj[videoSwipeCount];

                videoSwipeCount++;
                
                let toCurrentNum = videoSwipeCount;
                let toCurrentObj = videoListObj[toCurrentNum];

                let nextVideoNum = videoSwipeCount + 1;
                let nextObj = videoListObj[nextVideoNum];

                let toCurrentSrc = $(".next-tn").css("background-image");
                let currentSrc = $(".content-display").css("background-image");

                /* videoアスペクト比に応じてフルスクリーン表示かどうか分岐 */
                let isFulltoCurrent = $(".content-next").data("displayFull");

                let currentDisplayStyle = {};
                if(!isFulltoCurrent){
                    currentDisplayStyle = displayRepeat;
                } else {
                    currentDisplayStyle = displayFullscreen;
                }

                let isFulltoPrev = $(".content-current").data("displayFull");
                let prevDisplayStyle = {};              
                if(!isFulltoPrev){
                    prevDisplayStyle = displayRepeat;
                } else {
                    prevDisplayStyle = displayFullscreen;
                }

                $.when(
                    adjustXPos(nextVideoHeight, 2),
                    //$(".content-display img").attr("src", toCurrentSrc),
                    $(".content-display").css("background-image", toCurrentSrc),
                    $(".content-display").css(currentDisplayStyle),
                    $(".content-creator").find(".video-boundary-name").text(toCurrentObj.name),
                    $(".content-creator").find(".video-boundary-title").text(toCurrentObj.title),
                    $(".content-creator").find(".video-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + toCurrentObj.user_id + ".jpg)"),
                    $(".prev-tn").css("background-image", currentSrc),
                    $(".content-prev").data("displayFull", isFulltoPrev), 
                    $(".content-current").data("displayFull", isFulltoCurrent),  
                    $(".prev-tn").css(prevDisplayStyle),
                    $(".content-display").css("top", 0),
                    $(".content-next").css("top", windowHeight),                    
                   

                ).done(function(){
                    
                    if(videoSwipeCount == 1){
                        $('.content-prev').css('opacity', 1)
                    }

                    if(nextObj){
                        setVideo(nextObj, 2);
                    } else {
                        $('.content-next').css('opacity', 0)
                    }

                    $(".content-prev").find(".video-boundary-name").text(currentObj.name);
                    $(".content-prev").find(".video-boundary-title").text(currentObj.title);
                    $(".content-prev").find(".video-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + currentObj.user_id + ".jpg)");

                });

            });
        }

        function prevVideo(){

            if (videoSwipeCount == 0){
                returnCenter();
                return;
            }

            let scrollDist = 0 + windowHeight;
            let boudaryScrollDist =  50 - windowHeight;
            let prevVideoHeight = $(".prev-tn img").height();

            $.when(

                $(".content-display").animate({top : scrollDist}, 200),
                $(".video-boundary").animate({bottom : boudaryScrollDist}, 200),
                $(".content-prev").animate({bottom : 0}, 200),

            ).done(function(){

                videoSwipeCount--;

                let toCurrentNum = videoSwipeCount;
                let toCurrentObj = videoListObj[toCurrentNum];

                let toNextObj = videoListObj[videoSwipeCount + 1];

                let prevObj = videoListObj[videoSwipeCount - 1];

                let toCurrentSrc = $(".prev-tn").css("background-image");
                let currentSrc = $(".content-display").css("background-image");


                /* videoアスペクト比に応じてフルスクリーン表示かどうか分岐 */
                let isFulltoCurrent = $(".content-prev").data("displayFull");

                let currentDisplayStyle = {};
                if(!isFulltoCurrent){
                    currentDisplayStyle = displayRepeat;
                } else {
                    currentDisplayStyle = displayFullscreen;
                }

                let isFulltoNext = $(".content-current").data("displayFull");    
                let nextDisplayStyle = {};               
                if(!isFulltoNext){
                    nextDisplayStyle = displayRepeat;
                } else {
                    nextDisplayStyle = displayFullscreen;
                }

                $.when(

                    adjustXPos(prevVideoHeight),
                    //$(".content-display img").attr("src", toCurrentSrc),
                    $(".content-display").css("background-image", toCurrentSrc),
                    $(".content-display").css(currentDisplayStyle),
                    $(".content-creator").find(".video-boundary-name").text(toCurrentObj.name),
                    $(".content-creator").find(".video-boundary-title").text(toCurrentObj.title),
                    $(".content-creator").find(".video-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + toCurrentObj.user_id + ".jpg)"),
                    $(".next-tn").css("background-image", currentSrc),
                    $(".content-display").data("displayFull", isFulltoCurrent),
                    $(".next-tn").css(nextDisplayStyle),  
                    $(".content-display").css("top", 0),
                    $(".content-prev").css("bottom", windowHeight),
                    $(".content-next").data("displayFull", isFulltoNext),
                    $(".content-next").find(".video-boundary-name").text(toNextObj.name),
                    $(".content-next").find(".video-boundary-title").text(toNextObj.title),
                    $(".content-next").find(".video-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + toNextObj.user_id + ".jpg)"),

                ).done(function(){

                    if(videoSwipeCount == videoSwipeLength - 1){
                        $('.content-next').css('opacity', 1)
                    }

                    if(prevObj){
                        setVideo(prevObj, 0)
                    } else {
                        $('.content-prev').css('opacity', 0)
                    }

                });

            });
        }

        function returnCenter(){

            //$(".content-display").animate({top : startTop}, 200);
            $(".content-display").animate({top : 0}, 200);
            $(".video-boundary").animate({bottom : 60}, 200);
            $(".content-next").animate({top : windowHeight}, 200);
            $(".content-prev").animate({bottom : windowHeight}, 200);

        }

    }


/*
    スワイプ時のアクション
*/
    function touchMove( event ) {

        //タップ時のYをdataから取得
        let startY = $(".content-display").data("start-y");

        //スワイプ中の現在のYを関数から取得、dataに格納
        let currentY = Position(event);

        $(".content-display").data("current-y",currentY);

         //スワイプの上下方向に応じて処理を分岐
        if( currentY < startY ){
            //上にスワイプ

            //スワイプ距離を図りビデオを移動
            let swipeDistance = startY - currentY;

                //videoTop = firstVideoTop - swipeDistance;
            let videoTop = 0 - swipeDistance;

            $(".content-display").css({
                "top": videoTop,
            });

            //スワイプ距離を図りビデオクリエイターを移動
            let videoCreatorBottom = 60 + swipeDistance;
            $(".video-boundary").css("bottom", videoCreatorBottom);


            //次の要素を移動
            let videoCreatorTop = windowHeight - swipeDistance;
                videoCreatorTop = videoCreatorTop;
            $(".content-next").css("top", videoCreatorTop);

        }else{

            //スワイプ距離
            let swipeDistance = startY - currentY;

                //videoTop = firstVideoTop - swipeDistance ;
            let videoTop = 0 - swipeDistance;
                videoTop = videoTop;

            $(".content-display").css("top", videoTop);

            //スワイプ距離を図りビデオクリエイターを移動
            let videoCreatorBottom = 60 + swipeDistance;
            $(".video-boundary").css("bottom", videoCreatorBottom);

            //前の要素を移動
            let videoCreatorTop = windowHeight + swipeDistance
                videoCreatorTop = videoCreatorTop;
            $(".content-prev").css("bottom", videoCreatorTop);

        }
    }

/*
    実行時点のタッチ位置を返す
*/   
    function Position(e){
        //var x = e.originalEvent.touches[0].pageX;
        var y = e.originalEvent.touches[0].pageY;
        //x = Math.floor(x);
        y = Math.floor(y);
        return y;
    }   


/* }); */

};

export {touchVideo};