const header = () =>{

// スクロールを無効にする
document.addEventListener('touchmove', function(e) {e.preventDefault();}, {passive: false});

/*
共通変数
*/
    const windowWidth = $('body').data('innerW');
    const windowHeight = $('body').data('innerH');

    const openMenu = () => {

        let showMenuStyle = {
                "opacity" : 1,
            }

        let openMenuStyle = {
                "right" : 0,
            }

        $('.menu-wrapper').css(showMenuStyle);
        $('.menu-wrapper').animate(openMenuStyle, 400);
    }

    const closeMenu = () => {

        let showMenuStyle = {
            "opacity" : 0,
        }

        let closeMenuStyle = {
            "right" : windowWidth,
        }

        $('.menu-wrapper').animate(closeMenuStyle, 400);       

        setTimeout(() => {
            $('.menu-wrapper').css(showMenuStyle);
        }, 400);

    }

    const openSearch = () => {
        console.log("open Sea");
    }

    $('.header-menu').bind('click', openMenu);
    $('.menu-wrapper-header-text, .menu-wrapper-header-icon').bind('click', closeMenu);

    $('.header-search').bind('click', openSearch);
    //$('.header-share').bind('click', openShare);
    //$('.header-plus').bind('click', openPlus);

}

export {header};