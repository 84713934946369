// スクロールを無効にする
document.addEventListener('touchmove', function(e) {e.preventDefault();}, {passive: false});

/* $(document).ready(function(){ */

const touchMusic = (env) => {
    /*
    共通変数
    */
    let musicListObj = {}; //表示するビデオのリスト（後でajaxで取得）
    let windowHeight = $(window).height(); //デバイスのウインドウの高さ
    let musicSwipeCount = 0; //スワイプの回数。表示するmusicに影響
    let musicSwipeLength = 0; //スワイプの回数。表示するmusicに影響
    let sound = {};

    /*
        次のビデオ・前のビデオのセット
    */
    const prevDefaultPos = {
        "height" : windowHeight,
        "bottom" : windowHeight,
    }
    const nextDefaultPos = {
        "height" : windowHeight,
        "top" : windowHeight,
    }

    $(".music-prev").css(prevDefaultPos );
    $(".music-next").css(nextDefaultPos );


/* 
    webaudioAPIで鳴らすために、html5の無音音源をセット 
*/
    const html5 = new Howl({
      src: [env.storage + "music/0.m4a"],
      html5: true,
      volume: 0.1,
      loop: true,
    });

    let musicCount = 0;
    //let recommendMusics = new Object;


    const setMusic = (num) => {
        console.log(num)

        sound = new Howl({
          src: [
            env.storage + `music/${num}.m4a`,
            ],
          loop: true,
        });
    }

/*
    music-boundaryの位置を戻す
*/
    const adjustXPos = (status) => {
        $(".content-creator .music-boundary").css("bottom", 60);

    }

    const pausePlay = () =>{

        /* 最初はHTML5のロード時間に合わせるために遅延を入れる */
        let isFirst = $(".music-boundary").data("isfirst");

        if(sound.playing()){

            sound.pause();

        } else {
            html5.play();

            if(isFirst){
                setTimeout(function(){
                    sound.play();
                } ,300);
            } else {
                sound.play();
            }
           
            $(".music-boundary").data("isfirst", false);
        }
    }

/*
    タップした時
*/
    const touchStart = ( event ) => {

        var startY = Position(event);

        //タップ位置をdataに格納
        $(".content-music").data("start-y",startY);
        $(".content-music").data("current-y",startY);

        //タップ時の時間
        var startTime = performance.now();
        $(".content-music").data("start-time",startTime);

    }

/*
    次のboundaryをセット
*/
    const setHiddenBoundary = (obj, status) => {
        /* 
            引数statusでセット場所を分岐。
            0はprev, 1はcurrent, 2はnextにセット 
        */
       let targetClass = "";
       switch (status){
        case 0:
            targetClass = ".music-prev";
            break;
        case 1:
            break;
        case 2:
            targetClass = ".music-next";
            break;
        }

        $(targetClass).find(".music-boundary-name").text(obj.name);
        $(targetClass).find(".music-boundary-title").text(obj.title);
        $(targetClass).find(".music-boundary-icon").css("background-image", "url("+ env.storage +"user_icon/" + obj.user_id + ".jpg)");
    }

/*
    スワイプ時のアクション
*/
    const touchMove = ( event ) => {

        //タップ時のYをdataから取得
        var startY = $(".content-music").data("start-y");

        //スワイプ中の現在のYを関数から取得、dataに格納
        var currentY = Position(event);

        $(".content-music").data("current-y",currentY);

         //スワイプの上下方向に応じて処理を分岐
        if( currentY < startY ){
            //上にスワイプ

            //スワイプ距離を図り要素を移動
            let swipeDistance = startY - currentY;

            let musicBottom = 60 + swipeDistance;

            $(".music-boundary").css("bottom", musicBottom);

            //次の要素を移動
            let musicCreatorTop = windowHeight - swipeDistance
            $(".music-next").css("top", musicCreatorTop);

        }else{
            //下にスワイプ

            //スワイプ距離
            let swipeDistance = startY - currentY;

            let musicBottom = 60 + swipeDistance ;

            $(".music-boundary").css("bottom", musicBottom);

            //前の要素を移動
            var musicCreatorTop = windowHeight + swipeDistance
            $(".music-prev").css("bottom", musicCreatorTop);

        }
    }

/*
    指を離した時
*/
    const touchEnd = ( event ) => {
        //離したときの時間
        let endTime = performance.now();
        let startTime = $(".content-music").data("start-time");
        let swipeDur = endTime - startTime;

        // デフォルトのTOP位置を変数に入れる
        let startTop = $(".content-music").data("startTop");

        let startTopPx = startTop + "px";

            // 指を離した位置
        let touchEndY = $(".content-music").data("current-y");

            // タップを開始した位置
        let touchStartY = $(".content-music").data("start-y");

            // スワイプ距離を求める
        let swipeDist = touchStartY - touchEndY
        let swipeDistAbs = Math.abs(swipeDist);

        //スワイプ時間が100ms以下、スワイプ距離が60px以上なら切り替え
        if (swipeDur < 100 && swipeDist > 60) {

            nextMusic();

            } else if( swipeDur < 100 && swipeDist < (-60) ){

                prevMusic();

            //スワイプ距離が200px以上なら切り替え。199px以下ならもとに戻る
            } else if (swipeDistAbs < 200) {

                returnCenter();

            //上の境界までスワイプして消える
            } else if (swipeDist > 0) {

                nextMusic();

            //下の境界までスワイプして消える
            } else if (swipeDist < 0) {

                prevMusic();

            }


        function nextMusic(){

            if (musicSwipeCount == musicSwipeLength){
                returnCenter();
                return;
            }

            let scrollDist = 0 - (windowHeight - startTop);
            let boudaryScrollDist =  windowHeight + 50;

            /*
            nextがcurrent位置へ
            currentがprev位置へ
            移動しきったら
            */
            $.when(
                $(".content-music").animate({top : scrollDist}, 200),
                $(".music-boundary").animate({bottom : boudaryScrollDist}, 200),
                $(".music-next").animate({top : 0}, 200),

            /*
            nextの情報をcurrentに再現(API使う)
            （未作業）currentの情報をprevに再現
            nextとcurrentの位置を戻し
            nextに新しい動画を読み込む
            */
            ).done(function(){
                
                musicSwipeCount++;

                let toCurrentObj = musicListObj[musicSwipeCount];
                let toNextObj = musicListObj[musicSwipeCount + 1];
                let toPrevObj = musicListObj[musicSwipeCount - 1];

                $.when(
                    adjustXPos(0),
                    $(".content-creator").find(".music-boundary-name").text(toCurrentObj.name),
                    $(".content-creator").find(".music-boundary-title").text(toCurrentObj.title),
                    $(".content-creator").find(".music-boundary-icon").css("background-image", "url(" + env.storage + "user_icon/" + toCurrentObj.user_id + ".jpg)"),
                    $(".music-next").css("top", windowHeight), 
                    
                ).done(function(){

                    if(musicSwipeCount == 1){
                        $('.music-prev').css('opacity', 1)
                    }
                    if(toCurrentObj){

                        let playStatus = false;
                        if (sound.playing()){
                            playStatus = true;
                        }

                        if(playStatus) {
                            pausePlay();
                        }
                        setMusic(toCurrentObj.id, 2);

                        if(playStatus) {
                            pausePlay();
                        }

                        if(toNextObj){
                            setHiddenBoundary(toNextObj, 2);
                        }

                        if(toPrevObj){
                            setHiddenBoundary(toPrevObj, 0);
                        }

                    }

                    if(!toNextObj){
                        $('.music-next').css('opacity', 0);
                    }

                });

            });
        }

        function prevMusic(){

            if (musicSwipeCount == 0){
                returnCenter();
                return;
            }

            let scrollDist = startTop + windowHeight;
            let boudaryScrollDist =  50 - windowHeight;

            $.when(

                $(".content-music").animate({top : scrollDist}, 200),
                $(".music-boundary").animate({bottom : boudaryScrollDist}, 200),
                $(".music-prev").animate({bottom : 0}, 200),

            ).done(function(){

                musicSwipeCount--;

                let toCurrentObj = musicListObj[musicSwipeCount];
                let toNextObj = musicListObj[musicSwipeCount + 1];
                let toPrevObj = musicListObj[musicSwipeCount - 1];

                $.when(

                    adjustXPos(0),
                    $(".content-creator").find(".music-boundary-name").text(toCurrentObj.name),
                    $(".content-creator").find(".music-boundary-title").text(toCurrentObj.title),
                    $(".content-creator").find(".music-boundary-icon").css("background-image", "url("+ env.storage +"user_icon/" + toCurrentObj.user_id + ".jpg)"),
                    $(".music-prev").css("bottom", windowHeight),

                ).done(function(){

                    if(musicSwipeCount == musicSwipeLength - 1){
                        $('.music-next').css('opacity', 1)
                    }

                    if(toCurrentObj){

                        let playStatus = false;
                        if (sound.playing()){
                            playStatus = true;
                        }

                        if(playStatus) {
                            pausePlay();
                        }
                        setMusic(toCurrentObj.id, 0);

                        if(playStatus) {
                            pausePlay();
                        }
                        
                        if(toNextObj){
                            setHiddenBoundary(toNextObj, 2);
                        }

                        if(toPrevObj){
                            setHiddenBoundary(toPrevObj, 0);
                        }

                    }

                    if(musicSwipeCount == 0){
                        $('.music-prev').css('opacity', 0)
                    }

                });

            });
        }

        function returnCenter(){

            $(".content-music").animate({top : startTop}, 200);

            $(".music-boundary").animate({bottom : 60}, 200);

            $(".music-next").animate({top : windowHeight}, 200);

            $(".music-prev").animate({bottom : windowHeight}, 200);

        }

    }

    $(".content-music").bind("click", pausePlay);
    $(".content-music, .music-boundary").bind("touchstart", touchStart);
    $(".content-music, .music-boundary").bind("touchmove", touchMove);
    $(".content-music, .music-boundary").bind("touchend", touchEnd);

/*
    ロード時におすすめのmusic情報を取得するAjax、情報を描画
*/ 
    $.ajax({
        url: env.apiHost + "music/foryou",
        type: "GET",
        //data: data,
        timeout: 5000
        }).done(function(data){

            let recommendData = $.parseJSON(data);
                musicListObj = recommendData;
                musicSwipeLength = musicListObj.length - 1;

            let toNextObj = musicListObj[1];

            let firstMusic = musicListObj[0];

            $(".content-creator").find(".music-boundary-name").text(firstMusic.name);
            $(".content-creator").find(".music-boundary-title").text(firstMusic.title);
            $(".content-creator").find(".music-boundary-icon").css("background-image", "url("+ env.storage +"user_icon/" + firstMusic.user_id + ".jpg)");

            setMusic(firstMusic.id);

            setHiddenBoundary(toNextObj, 2);

        }).fail(function(){


        });


 /*
    実行時点のタッチ位置を返す
*/   
    const Position = (e) => {
        //var x = e.originalEvent.touches[0].pageX;
        let y = e.originalEvent.touches[0].pageY;
        //x = Math.floor(x);
        y = Math.floor(y);
        return y;
    }  

/* }); */

};

export {touchMusic};












